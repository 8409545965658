var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_c('v-select',{attrs:{"items":_vm.views,"value":_vm.mode,"dense":""},on:{"change":_vm.viewChanged}}),_vm._v("    von "+_vm._s(_vm.fmt(_vm.von))+" bis "+_vm._s(_vm.fmt(_vm.bis))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.isAppLoading,"loading-text":_vm.loadingText,"headers":_vm.headers,"items":_vm.liste,"item-key":"uid","search":_vm.search,"options":_vm.options,"show-expand":"","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"footer-props":{
            showFirstLastPage: true,
            'items-per-page-text': 'Zeilen pro Seite',
         }},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.loadDetails},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
         var headers = ref.headers;
         var item = ref.item;
return [_c('td',{staticClass:"detail-cell",attrs:{"colspan":headers.length}},[_c('buch-verlags-verkaeufe-detail',{attrs:{"item":item}})],1)]}},{key:"item.summary.betrag",fn:function(ref){
         var item = ref.item;
return [_vm._v(" "+_vm._s(null !== item.summary ? item.summary.betragString : "")+" ")]}},{key:"item.buchListe.pills",fn:function(ref){
         var item = ref.item;
return [(item.buchListe.bestseller == 'Y')?_c('v-chip',{staticClass:"bestseller",attrs:{"x-small":""}},[_vm._v("BESTSELLER")]):_vm._e(),(item.buchListe.bestand == 'Y')?_c('v-chip',{staticClass:"bestand",attrs:{"x-small":"","color":"primary"}},[_vm._v("BESTAND")]):_vm._e()]}},{key:"item.state",fn:function(ref){
         var item = ref.item;
return [(!item.isLoaded && item.isLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","rotate":"item.buchVerlag.vn","color":"secondary"}}):_vm._e(),(!item.isLoaded && !item.isLoading)?_c('v-icon',[_vm._v("mdi-cloud-outline")]):_vm._e()]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" Statistik: Zeilen "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" (von "+_vm._s(items.itemsLength)+") ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }