
























































































import {
     VerkaufsStatistik,
     VerkaufsStatistikModes,
} from "@/models/util/BuchVerlagsVerkaeufeDisplayModel";
import { catNav, catVue } from "@/util/logging";
import { format, subDays, subMonths } from "date-fns";

import { Api } from "@/util/api";
import { BuchListeModel } from "@/models/BuchListeModel";
import { BuchVerlagModel } from "@/models/BuchVerlagModel";
import Datum from "@/util/datum";
import { Route } from "vue-router";
import Vue from "vue";
import { mapState } from "vuex";
import { toNum } from "@/util/converters";

// Datums-Unterstützung
   
   
      
         
   interface ExpandEvent {
     item: VerkaufsStatistik;
     value: boolean;
   }

   export default Vue.extend({
     //
     name: "BuchVerlagsVerkäufe",

     props: {},

     components: {
       BuchVerlagsVerkaeufeDetail: () =>
         import(
           /* webpackChunkName: "BuchVerlagsVerkaeufeDetail" */ "@/components/statistik/BuchVerlagsVerkaeufeDetail.vue"
         ),
     },

     data() {
       return {
         // The Range
         von: "?",
         bis: "?",
         mode: 0 as VerkaufsStatistikModes,

         sequence: 0,
         lastFetch: "",

         headers: [] as Record<string, string | boolean>[],

         // The Views
         views: [
           {
             text: "Buchverkäufe nach Verlag",
             value: VerkaufsStatistikModes.VerkaufVerlag,
             disabled: false,
           },
           {
             text: "Wareneingang ohne Verkauf nach Verlag",
             value: VerkaufsStatistikModes.NichtverkaufVerlag,
             disabled: false,
           },
           {
             text: "Buchverkäufe nach Liste",
             value: VerkaufsStatistikModes.VerkaufListe,
             disabled: false,
           },
           {
             text: "Wareneingang ohne Verkauf nach Liste",
             value: VerkaufsStatistikModes.NichtverkaufListe,
             disabled: false,
           },
         ],

         // "The Data"
         liste: new Array<VerkaufsStatistik>(),

         // For getting the stuff ...
         toFetch: new Array<VerkaufsStatistik>(),

         // For the Data-Table
         expanded: [],
         singleExpand: false,

         search: "",
         options: {
           sortBy: [] as string[],
           multiSort: true,
         },
       };
     },

     methods: {
       fmt(datum: string) {
         return Datum.datumDeutsch(datum);
       },

       fetch() {
         if (this.toFetch.length == 0 && Api.allDone()) {
           catVue.info("Fetch seems done ...");
           this.$store.commit("changeAppLoadingState", false, {
             root: true,
           });
           return;
         }

         for (let index = 0; index < 10 - Api.countWaiting(); index++) {
           this.fetchNext();
         }

         setTimeout(() => {
           this.fetch();
         }, 150);
       },

       fetchNext() {
         this.$store.commit(
           "changePercentComplete",
           { current: -this.toFetch.length - 1, total: this.liste.length + 1 },
           { root: true }
         );

         const element = this.toFetch.shift();
         if (element !== undefined) element.fetch();
       },

       getRangeFromURL() {
         let von = this.von;
         let bis = this.bis;

         if (this.$route.query["von"])
           von = Datum.datumISO(this.$route.query["von"] as string);

         if (this.$route.query["bis"])
           bis = Datum.datumISO(this.$route.query["bis"] as string);

         const range = Datum.sort(von, bis);
         var change = false;
         if (this.von !== range.start) {
           change = true;
           this.von = range.start;
         }
         if (this.bis !== range.end) {
           change = true;
           this.bis = range.end;
         }

         let viewMode = toNum(this.$route.query["mode"]);
         if (undefined !== viewMode && VerkaufsStatistik.isValidMode(viewMode)) {
           if (this.mode != viewMode) {
             change = true;
             this.mode = viewMode;
             catVue.info(`Got viewMode (from Route?): ${this.mode}`);
           }
         }

         if (change) {
           catVue.info("Change in range ... will start loading");
           this.startLoading();
         }
       },

       async loadDetails(event: ExpandEvent) {
         if (event?.value) {
           const stat = event.item;
           if (null !== stat && undefined !== stat) stat.loadDetails();
         }
       },

       clearAll() {
         this.liste.splice(0);
         this.toFetch.splice(0);
         this.expanded.splice(0);
         Api.cancelAllWaiting();

         this.headers.splice(0);
         VerkaufsStatistik.setup(this.von, this.bis, this.mode);
         this.headers.push(...VerkaufsStatistik.viewHeaders());
       },

       viewChanged(newValue: number) {
         if (VerkaufsStatistik.isValidMode(newValue)) {
           if (this.mode != newValue) {
             this.mode = newValue;

             this.$router
               .replace({
                 path: this.$route.path,
                 query: { ...this.$route.query, mode: "" + this.mode },
               })
               .catch(() => {
                 /* intentionally left empty */
               });

             this.$nextTick(this.startLoading);
           }
         }
       },

       startLoading() {
         catVue.info("startLoading .. ");
         let fetch = this.von + "," + this.bis + "," + this.mode;
         if (this.lastFetch == fetch) return;

         this.lastFetch = fetch;
         this.clearAll();
         VerkaufsStatistik.setup(this.von, this.bis, this.mode);

         this.$store.commit("changeAppLoadingState", true, { root: true });
         var counter = ++this.sequence;

         VerkaufsStatistik.getListe()
           .then((response) => {
             catVue.debug("in Response ..." + JSON.stringify(response));
             if (this.sequence != counter) {
               catVue.info(
                 "Something seems to have changed. Will not publish results"
               );
               return;
             }

             if (Array.isArray(response)) {
               catVue.info("Packing from List ...");
               let summary = null as VerkaufsStatistik | null;
               if (this.mode === VerkaufsStatistikModes.VerkaufVerlag) {
                 summary = new VerkaufsStatistik(null);
                 this.liste.push(summary);
               }

               response.forEach((element: BuchVerlagModel | BuchListeModel) => {
                 const stat = new VerkaufsStatistik(element);
                 this.liste.push(stat);
                 this.toFetch.push(stat);
               });

               if (summary !== null) {
                 this.toFetch.push(summary);
                 catVue.info("Pushed summary to fetch ...");
               }
             }

             catVue.info("At end of then() ... liste loaded!");

             if (this.toFetch.length > 0) this.$nextTick(this.fetch);
           })
           .catch((error) => {
             catVue.error("Fehler beim Laden der Verlage ..." + error, null);
             this.$store.commit("changeAppLoadingState", false, {
               root: true,
             });
           });
       },
     },

     computed: {
       loadingText(): string {
         return "Lade ... " + this.liste.length + " Einträge";
       },

       ...mapState(["isAppLoading"]),
     },

     watch: {
       $route(to: Route, from: Route) {
         catNav.info(
           "Route Change FROM=>>" +
             from.fullPath +
             "<< => TO=>>" +
             to.fullPath +
             "<<"
         );
         this.getRangeFromURL();
       },
     },

     created() {
       catVue.info(
         "created: BuchBewegungen auf " +
           this.$route.fullPath +
           " mit " +
           JSON.stringify(this.$route.query, undefined, 2)
       );
       catVue.info("von/bis = " + this.von + "/" + this.bis + " ... vor Query");

       // Defaults, bevor wir auf die URL schauen …
       //
       const start = subDays(new Date(), 1);
       this.bis = format(start, "yyyy-MM-dd");
       this.von = format(subMonths(start, 1), "yyyy-MM-dd");
       VerkaufsStatistik.setup(this.von, this.bis, this.mode);
     },

     mounted() {
       catVue.info("mounted: BuchBewegungen von " + this.von + "–" + this.bis);

       this.$nextTick(this.getRangeFromURL);
     },

     beforeDestroy() {
       catVue.info(
         `${this.$options.name}:: BEFOREDESTROY ... mit ${this.toFetch.length} to process ... `
       );
       this.clearAll();
     },
   });
