import { BuchListeModel, BuchListenEintragModel } from "../BuchListeModel";
import { BuchVerkaufModel, BuchVerlagModel, BuchVerlagSummaryModel } from "../BuchVerlagModel";

import { catVue } from "@/util/logging";

export enum VerkaufsStatistikModes {
    VerkaufVerlag = 0,
    NichtverkaufVerlag,
    VerkaufListe,
    NichtverkaufListe,
}

// Antwort-Datensatz
export class VerkaufsStatistik {

    uid: string;

    buchVerlag: BuchVerlagModel | undefined;
    buchListe: BuchListeModel | undefined;
    summary: BuchVerlagSummaryModel | null;
    details: Array<BuchVerkaufModel>;

    isLoaded: boolean;
    isLoading: boolean;
    detLoaded: boolean;
    detLoading: boolean;
    detStep = 0;

    static von: string;
    static bis: string;
    static mode: VerkaufsStatistikModes;

    constructor(anchor: BuchVerlagModel | BuchListeModel | null) {

        if (anchor === null) {

            this.uid = "-summary-"
            this.buchVerlag = new BuchVerlagModel
            this.buchVerlag.name = "— Zusammenfassung —"
            this.buchVerlag.vn = 0
            this.buchVerlag.url = "/buchverlage/-"
        } else {

            this.uid = anchor.uid
            if (anchor instanceof BuchVerlagModel)
                this.buchVerlag = anchor;
            else
                this.buchListe = anchor;
        }

        this.summary = null;
        this.details = new Array<BuchVerkaufModel>();
        this.isLoaded = false;
        this.isLoading = false;
        this.detLoaded = false;
        this.detLoading = false;
    }

    static isValidMode(inMode: number): boolean {
        if (inMode < VerkaufsStatistikModes.VerkaufVerlag || inMode > VerkaufsStatistikModes.NichtverkaufListe) return false
        return true
    }

    static viewString(): string {

        if (VerkaufsStatistik.mode == VerkaufsStatistikModes.VerkaufListe ||
            VerkaufsStatistik.mode == VerkaufsStatistikModes.VerkaufVerlag) return "verkauf"

        if (VerkaufsStatistik.mode == VerkaufsStatistikModes.NichtverkaufListe ||
            VerkaufsStatistik.mode == VerkaufsStatistikModes.NichtverkaufVerlag) return "nichtverkauf"

        catVue.error("Should not get here, invalid mode: " + VerkaufsStatistik.mode, null)
        return ""
    }

    static viewHeaders(): Record<string, string | boolean>[] {

        catVue.info(`viewHeaders - called with ${VerkaufsStatistik.mode}`)
        switch (VerkaufsStatistik.mode) {
            case VerkaufsStatistikModes.VerkaufListe:
                return [
                    {
                        text: "",
                        align: "start",
                        value: "state",
                    },
                    {
                        text: "Typ",
                        align: "start",
                        value: "buchListe.pills",
                    },
                    { text: "Liste", value: "buchListe.name", align: "start" },
                    {
                        text: "Verkauf (im Zeitraum)",
                        value: "summary.stueck",
                        align: "end",
                    },
                    {
                        text: "Umsatz (im Zeitraum)",
                        value: "summary.betrag",
                        align: "end",
                    },
                    { text: "", value: "data-table-expand" },
                ];
            case VerkaufsStatistikModes.VerkaufVerlag:
                return [
                    {
                        text: "",
                        align: "start",
                        value: "state",
                    },
                    {
                        text: "Verkehrsnummer",
                        align: "end",
                        sortable: true,
                        value: "buchVerlag.vn",
                    },
                    { text: "Verlag", value: "buchVerlag.name", align: "start" },
                    {
                        text: "Verkauf (im Zeitraum)",
                        value: "summary.stueck",
                        align: "end",
                    },
                    {
                        text: "Umsatz (im Zeitraum)",
                        value: "summary.betrag",
                        align: "end",
                    },
                    { text: "", value: "data-table-expand" },
                ];
            case VerkaufsStatistikModes.NichtverkaufListe:
                return [
                    {
                        text: "",
                        align: "start",
                        value: "state",
                    },
                    {
                        text: "Typ",
                        align: "start",
                        value: "buchListe.pills",
                    },
                    { text: "Liste", value: "buchListe.name", align: "start" },
                    {
                        text: "Wareneingang (im Zeitraum)",
                        value: "summary.stueck",
                        align: "end",
                    },
                    {
                        text: "Warenwert (im Zeitraum)",
                        value: "summary.betrag",
                        align: "end",
                    },
                    { text: "", value: "data-table-expand" },
                ];
            case VerkaufsStatistikModes.NichtverkaufVerlag:
                return [
                    {
                        text: "",
                        align: "start",
                        value: "state",
                    },
                    {
                        text: "Verkehrsnummer",
                        align: "end",
                        sortable: true,
                        value: "buchVerlag.vn",
                    },
                    { text: "Verlag", value: "buchVerlag.name", align: "start" },
                    {
                        text: "Wareneingang (im Zeitraum)",
                        value: "summary.stueck",
                        align: "end",
                    },
                    {
                        text: "Warenwert (im Zeitraum)",
                        value: "summary.betrag",
                        align: "end",
                    },
                    { text: "", value: "data-table-expand" },
                ];
            default:
                catVue.warn("STRANGE MODE!")
                return []
        }



    }

    static setup(von: string, bis: string, mode: VerkaufsStatistikModes): void {

        VerkaufsStatistik.von = von;
        VerkaufsStatistik.bis = bis;
        VerkaufsStatistik.mode = mode;
    }

    public fetch(): void {

        this.isLoading = true;

        let query: Promise<BuchVerlagSummaryModel | null> | null = null

        if (this.buchVerlag !== undefined)
            query = BuchVerlagSummaryModel.zuVerlagInZeitraum(
                this.buchVerlag,
                VerkaufsStatistik.von,
                VerkaufsStatistik.bis,
                VerkaufsStatistik.viewString()
            )

        if (this.buchListe !== undefined)
            query = BuchVerlagSummaryModel.zuListeInZeitraum(
                this.buchListe,
                VerkaufsStatistik.von,
                VerkaufsStatistik.bis,
                VerkaufsStatistik.viewString()
            )

        if (null === query) {

            return
        }
        query.then((response) => {
            this.summary = response;
            this.isLoaded = true;
        })
            .catch((err) => {
                catVue.error("Hmmm. Some error " + err, null);
            });
    }

    public loadDetails(): void {
        //
        if (this.summary == null) {
            catVue.info("opened slot that is not yet loaded …");
            setTimeout(() => {
                this.loadDetails();
            }, 250);
            return;
        }

        if (!this.detLoading) {
            //
            this.detLoading = true;

            let query: Promise<BuchVerkaufModel[]> | null = null
            if (this.buchVerlag !== undefined)
                query = BuchVerkaufModel.zuVerlagInZeitraum(
                    this.buchVerlag,
                    VerkaufsStatistik.von,
                    VerkaufsStatistik.bis,
                    VerkaufsStatistik.viewString() == "verkauf" ? "VERK%" : "LIEF%"
                )
            if (this.buchListe !== undefined)
                query = BuchVerkaufModel.zuListeInZeitraum(
                    this.buchListe,
                    VerkaufsStatistik.von,
                    VerkaufsStatistik.bis,
                    VerkaufsStatistik.viewString() == "verkauf" ? "VERK%" : "LIEF%"
                )

            if (null === query) {

                catVue.warn("Neither Verlag nor Liste: " + JSON.stringify(this))
                this.detLoading = true;
                return
            }
            query.then((details: BuchVerkaufModel[]) => {
                this.details.push(...details);
                this.detLoaded = true;

                catVue.info(
                    "Details loaded  ...  loading Lieferungen und Remissionen"
                );

                if (VerkaufsStatistik.viewString() == "verkauf") {
                    this.addDetails("LIEF%", "lieferung");
                } else {
                    this.subDetails("VERK%");
                }
                this.addDetails("BEST%", "bestellung");
                this.addDetails("REMI%", "remission");
                if (this.buchListe !== undefined) {
                    //                    catVue.error("Hier würde die gesamte liste geladen ...", null);
                    BuchListenEintragModel.byListe(this.buchListe.url)
                        .then((eintraege: BuchListenEintragModel[]) => {
                            for (const eintrag of eintraege) {
                                //                                catVue.error("-> eintrag:" + eintrag.burl, null);
                                for (const lst of this.details) {
                                    //                                    catVue.error("lst:" + lst.buchUrl + " <-> eintrag:" + eintrag.burl, null);
                                    if (
                                        lst.buchUrl == eintrag.burl
                                    ) {
                                        lst.position = eintrag.seq
                                    }
                                }
                            }
                        })
                }
            }
            ).catch((e) => { catVue.error("Fetch error: " + e, null) })
        }
    }

    public addDetails(art: string, key: string): void {
        let query: Promise<BuchVerkaufModel[]> | null = null
        if (this.buchVerlag !== undefined)
            query = BuchVerkaufModel.zuVerlagInZeitraum(
                this.buchVerlag,
                VerkaufsStatistik.von,
                VerkaufsStatistik.bis,
                art
            )
        if (this.buchListe !== undefined)
            query = BuchVerkaufModel.zuListeInZeitraum(
                this.buchListe,
                VerkaufsStatistik.von,
                VerkaufsStatistik.bis,
                art
            )

        if (null === query) {

            catVue.warn("Neither Verlag nor Liste: " + JSON.stringify(this))
            this.detLoading = true;
            return
        }
        query.
            then((entries) => {
                catVue.info(`${key} loaded  ...  adding / updating in Liste`);

                for (const entry of entries) {
                    for (const lst of this.details) {
                        if (
                            lst.buchUrl == entry.buchUrl &&
                            lst.filialeURL == entry.filialeURL
                        ) {
                            const item = ((lst as unknown) as Record<string, number | string>)
                            //
                            // console.log("Vorher: ", entry, lst);
                            item["_" + key] = entry.stueck;
                            item[key + "MinDatum"] = entry.minDatum;
                            item[key + "MaxDatum"] = entry.maxDatum;
                            // console.log("Nachher: ", entry, lst);
                        }
                    }
                }
                this.detStep++;
            });
    }

    public subDetails(art: string): void {
        let query: Promise<BuchVerkaufModel[]> | null = null
        if (this.buchVerlag !== undefined)
            query = BuchVerkaufModel.zuVerlagInZeitraum(
                this.buchVerlag,
                VerkaufsStatistik.von,
                VerkaufsStatistik.bis,
                art
            )
        if (this.buchListe !== undefined)
            query = BuchVerkaufModel.zuListeInZeitraum(
                this.buchListe,
                VerkaufsStatistik.von,
                VerkaufsStatistik.bis,
                art
            )

        if (null === query) {

            catVue.warn("Neither Verlag nor Liste: " + JSON.stringify(this))
            this.detLoading = true;
            return
        }
        query.then((details) => {
            //
            catVue.info(`sub: ${art} loaded  ...  removing from Liste`);

            for (const entry of details) {
                // console.log(
                //    `Hunting for ${JSON.stringify(entry)}  in List with ${
                //       stat.details.length
                //    } items`,
                //    stat.details
                // );
                for (let i = 0; i < this.details.length; i++) {
                    const lst = this.details[i];
                    if (lst.buchUrl == entry.buchUrl) {
                        // console.log(
                        //    `Removing ${JSON.stringify(
                        //       lst
                        //    )} from pos ${i} in List with ${
                        //       stat.details.length
                        //    } items`
                        // );
                        this.details.splice(i, 1);
                        // console.log(
                        //    `After... ${i} in List is ${JSON.stringify(
                        //       stat.details[i]
                        //    )} and we have ${stat.details.length} items`,
                        //    stat.details
                        // );
                        i--;
                    }
                }
            }
            this.detStep++;
        });
    }



    static getListe(): Promise<BuchVerlagModel[] | BuchListeModel[]> {

        switch (VerkaufsStatistik.mode) {
            case VerkaufsStatistikModes.NichtverkaufListe:
            case VerkaufsStatistikModes.VerkaufListe:

                return BuchListeModel.mitVerkaufInZeitraum(
                    this.von,
                    this.bis,
                    VerkaufsStatistik.viewString()
                )


            case VerkaufsStatistikModes.NichtverkaufVerlag:
            case VerkaufsStatistikModes.VerkaufVerlag:

                return BuchVerlagModel.mitVerkaufInZeitraum(
                    this.von,
                    this.bis,
                    VerkaufsStatistik.viewString()
                )


            default:
                catVue.warn(`Strange mode: ${VerkaufsStatistik.mode}`)
                throw new Error(`Strange mode: ${VerkaufsStatistik.mode}`);
        }
    }
}

