import { BaseType } from "./util/BaseType";
import { BuchListeModel } from "./BuchListeModel";
import { Expose } from "class-transformer";
import { api } from '@/util/api';
import { catApi } from '@/util/logging';
import format from 'date-fns/format';
import hyphenateISBN from "@/util/isbn";
// === === === === === === === === === === === === === === === === === === === === === === === === === === ===
import parseISO from 'date-fns/parseISO';

export class BuchVerlagModel extends BaseType {

    @Expose({ name: "uid" }) uid = '';
    @Expose({ name: "vn" }) vn = -1;
    @Expose({ name: "name" }) name = '';
    @Expose({ name: "url" }) url = '';

    // === === === === === === === === === === === === === === === === === === === === === === ===

    static mitVerkaufInZeitraum = async (von: string, bis: string, view: string): Promise<BuchVerlagModel[]> => {

        catApi.info("mitVerkaufInZeitraum: " + von + "..." + bis);

        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response = await api.get<BuchVerlagModel[]>(
                "v1/statistiken/buchverkaeufe", {
                params: { 'von': von, 'bis': bis, 'view': view, 'nach': "verlag" }
            }
            );
            catApi.debug("response: /v1/statistiken/buchverkaeufe -> " + JSON.stringify(response));

            return BaseType.convertArray(BuchVerlagModel, response.data)
        }
        catch (e) {

            const err = new Error(JSON.stringify(e))
            catApi.error("caught Error: " + err.message, err);
            return Promise.reject(err)
        }
    }
}

// === === === === === === === === === === === === === === === === === === === === === === === === === === ===

export class BuchVerlagSummaryModel extends BaseType {

    @Expose({ name: "uid" }) uid = '';
    @Expose({ name: "positionen" }) positionen = 0;
    @Expose({ name: "stueck" }) stueck = 0;
    @Expose({ name: "betrag" }) betrag = 0.0;

    get betragString(): string {

        return BaseType.currencyFormatter.format(this.betrag)
    }

    // === === === === === === === === === === === === === === === === === === === === === === === === === === === === === ===

    static zuVerlagInZeitraum = async (verlag: BuchVerlagModel, von: string, bis: string, view: string): Promise<BuchVerlagSummaryModel | null> => {

        catApi.debug("zuVerlagInZeitraum: " + von + "..." + bis);
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const response = await api.get<BuchVerlagSummaryModel>(
                "v1/statistiken/buchverkaeufe" + verlag.url + "/sum", {
                params: { 'von': von, 'bis': bis, 'view': view }
            }
            );
            catApi.debug("response: /v1/statistiken/buchverkaeufe" + verlag.url + "/sum -> " + JSON.stringify(response));

            return BaseType.convert(BuchVerlagSummaryModel, response.data)
            //
        } catch (e) {

            const err = new Error(JSON.stringify(e))
            catApi.error("caught Error: " + err.message, err);
            return Promise.reject(err)
        }
    }

    // === === === === === === === === === === === === === === === === === === === === === === === === === === === === === ===

    static zuListeInZeitraum = async (liste: BuchListeModel, von: string, bis: string, view: string): Promise<BuchVerlagSummaryModel | null> => {

        catApi.debug("zuListeInZeitraum: " + von + "..." + bis);
        try {
            const path = `v1/statistiken/buchverkaeufe${liste.url}/sum`
            const response = await api.get<BuchVerlagSummaryModel>(
                path, {
                params: { 'von': von, 'bis': bis, 'view': view }
            }
            );
            catApi.debug(`response: /${path} -> ` + JSON.stringify(response));

            return BaseType.convert(BuchVerlagSummaryModel, response.data)
            //
        } catch (e) {

            const err = new Error(JSON.stringify(e))
            catApi.error("caught Error: " + err.message, err);
            return Promise.reject(err)
        }
    }
}


function fmt(inp: string): string {

    if (inp === "") return "";

    return format(parseISO(inp), 'dd.MM.yyyy')
}

function fmt2(in1: string, in2: string): string {

    if (in1 === "") return "";

    if (in1 === in2) return fmt(in1)
    return fmt(in1) + "–" + fmt(in2)
}

export class BuchVerkaufModel extends BaseType {

    @Expose({ name: "uid" }) uid = '';

    @Expose({ name: "buchEAN" }) buchEAN = '';
    @Expose({ name: "buchEVT" }) buchEVT = '';
    @Expose({ name: "buchUrl" }) buchUrl = '';
    @Expose({ name: "buchTitel" }) buchTitel = '';
    @Expose({ name: "auflage" }) auflage = '';
    @Expose({ name: "positionen" }) positionen = 0;

    @Expose({ name: "bestand" }) bestand = 0;

    @Expose({ name: "stueck" }) stueck = 0;
    @Expose({ name: "maxDatum" }) maxDatum = '';
    @Expose({ name: "minDatum" }) minDatum = '';
    @Expose({ name: "lieferung" }) _lieferung = 0;
    @Expose({ name: "bestellung" }) _bestellung = 0;
    @Expose({ name: "remission" }) _remission = 0;

    lieferungMinDatum = '';
    lieferungMaxDatum = '';

    remissionMinDatum = '';
    remissionMaxDatum = '';

    bestellungMinDatum = '';
    bestellungMaxDatum = '';

    @Expose({ name: "verkauf" }) verkauf = 0.0;
    @Expose({ name: "umsatz" }) umsatz = 0.0;

    @Expose({ name: "filialeName" }) filialeName = '';
    @Expose({ name: "filialeUrl" }) filialeURL = '';
    @Expose({ name: "filiale" }) filiale = 0;

    @Expose() buchverlagVN = 0;
    @Expose() buchverlagName = '';

    position = 0;

    get evt(): string {

        if ('' == this.buchEVT) return ''

        //        console.log("parse:", parseISO(this.buchEVT))
        return format(parseISO(this.buchEVT), 'dd.MM.yyyy')
    }

    get bestellung(): number | string {
        if (this._bestellung == 0) return ''
        return this._bestellung;
    }

    get lieferung(): number | string {
        if (this._lieferung == 0) return ''
        return this._lieferung;
    }

    get lieferungDatum(): string {
        if (this._lieferung === 0) return ''
        return fmt2(this.lieferungMinDatum, this.lieferungMaxDatum)
    }

    get remissionDatum(): string {
        if (this._remission === 0) return ''
        return fmt2(this.remissionMinDatum, this.remissionMaxDatum)
    }

    get datum(): string {
        if (this.stueck === 0) return ''
        return fmt2(this.minDatum, this.maxDatum)
    }

    get remission(): number | string {
        if (this._remission == 0) return ''
        return this._remission;
    }

    get verkaufString(): string {
        return BaseType.currencyFormatter.format(this.verkauf)
    }

    get umsatzString(): string {
        return BaseType.currencyFormatter.format(this.umsatz)
    }

    get EAN(): string {
        return hyphenateISBN(this.buchEAN)
    }

    // === === === === === === === === === === === === === === === ===

    static zuVerlagInZeitraum = async (verlag: BuchVerlagModel, von: string, bis: string, art: string): Promise<BuchVerkaufModel[]> => {

        catApi.debug("zuVerlagInZeitraum: " + von + "..." + bis);
        try {
            const path = `v1/statistiken/buchverkaeufe${verlag.url}`
            const response = await api.get<BuchVerkaufModel[]>(path, {
                params: {
                    'von': von, 'bis': bis, 'art': art
                }
            });
            catApi.debug(`response: ${path} -> ${JSON.stringify(response)}`);

            return BaseType.convertArray(BuchVerkaufModel, response.data)
        }
        catch (e) {

            const err = new Error(JSON.stringify(e))
            catApi.error("caught Error: " + err.message, err);
            return Promise.reject(err)
        }
    }

    // === === === === === === === === === === === === === === === ===

    static zuListeInZeitraum = async (liste: BuchListeModel, von: string, bis: string, art: string): Promise<BuchVerkaufModel[]> => {

        catApi.debug("zuListeInZeitraum: " + von + "..." + bis);
        try {
            const path = `v1/statistiken/buchverkaeufe${liste.url}`
            const response = await api.get<BuchVerkaufModel[]>(path, {
                params: {
                    'von': von, 'bis': bis, 'art': art
                }
            });
            catApi.debug(`response: ${path} -> ${JSON.stringify(response)}`);

            return BaseType.convertArray(BuchVerkaufModel, response.data)
        }
        catch (e) {

            const err = new Error(JSON.stringify(e))
            catApi.error("caught Error: " + err.message, err);
            return Promise.reject(err)
        }
    }
}
